import React from "react";
import PropTypes from "prop-types";

import styles from "assets/scss/pages/Menu/repasContainer.module.scss";
import { Button } from "lib/components";
import useUI from "hooks/ui.hook";
import useFeature from "hooks/useFeature";
import { sortMenus, hasBooked, isRepasEmpty, isMenuEmpty } from "../../helpers/operations";
import { HomeOutline } from "react-ionicons";
import moment from "moment";

import { useMenu } from "../../router_context";
import { MenuCard } from "../../router_components";

const Repas = ({ repas }) => {
    const [ctx, dispatch] = useMenu();
    const [ui] = useUI();
    const dateWall = moment(ctx?.selectedDate).add(1, "d").subtract(ctx.delay ?? 0, "d").isAfter(moment());

    const isReservation = useFeature("reservationMenu");
    const template = ui.establishment.template;

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {repas}
            </div>
            {dateWall && isReservation && !ctx.disabled && !isRepasEmpty(ctx.data[ctx.selectedDate][repas]) ?
                <div className={styles.reservationHeader}>
                    <div>
                        {ctx?.data[ctx?.selectedDate]?.reservation &&
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid] &&
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas] &&
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas][0] &&
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas][0].homeDelivery === true ?
                            <HomeOutline width={"30px"} height={"30px"} />
                            : null}

                    </div>
                    <div className={styles.buttonContainer}>
                        <Button color="primary" onClick={() => {
                            if (hasBooked(ctx.data[ctx.selectedDate], ui.user.uid, repas)) {
                                dispatch({ type: "setProperty", property: "modalResumeOpen", value: true });
                                } else {
                                dispatch({ type: "setProperty", property: "modalResaOpen", value: true });
                            }
                            dispatch({ type: "setProperty", property: "modalInfos", value: { repas: repas } });
                        }}>{hasBooked(ctx.data[ctx.selectedDate], ui.user.uid, repas) ? "Modifier" : "Réserver"}</Button>

                    </div>
                    <div>
                        {ctx?.data[ctx?.selectedDate]?.reservation &&
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid] &&
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas] &&
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas].length > 0 ?
                            ctx?.data[ctx?.selectedDate]?.reservation[ui.user.uid][repas].length + " repas"
                            : null}
                    </div>
                </div>

                : null}
            {isRepasEmpty(ctx.data[ctx.selectedDate][repas]) ?
                <div className={styles.noMenu}>
                    Aucun menu défini pour le {repas} de ce jour
                </div>
                :
                <div className={styles.cardContainer}>
                    {sortMenus(template, repas, Object.keys(template[repas].menus)).map((menu, index) => (
                        !isMenuEmpty(ctx.data[ctx.selectedDate][repas], menu) ?
                            <div key={index}>
                                <MenuCard repas={repas} menu={menu} />
                            </div>
                            : null
                    ))}
                </div>
            }



        </div>
    );
};

Repas.propTypes = {
    repas: PropTypes.string.isRequired,
};

export default Repas;