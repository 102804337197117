import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../lib/components";
import React, { useEffect, useState, useMemo } from "react";

import PropTypes from "prop-types";
import moment from "moment";
import useFeature from "../../../hooks/useFeature";
import useUI from "../../../hooks/ui.hook";
import { toast } from "react-toastify";
import { Calendar, MapPin, Tag, Users, X } from "react-feather";

import firebase from "services/firebase";

import styles from "../../../assets/scss/pages/Animation/eventModal.module.scss";
import useAnimations from "./ContextAnimation";

import { sendNotifToMajor } from "services/notifications";

const firestore = firebase.firestore;

const EventModal = () => {
  const [ctx, dispatch] = useAnimations();

  const [ui] = useUI();
  const [modalSubscribe, setModalSubscribe] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", reason: "" });

  const isReservation = useFeature("reservationPlanning");
  const isSenior = ["senior", "seniorTotem"].includes(ui.user.role);

  const _events = ctx.events ? ctx.events.reduce((acc, obj) => { const key = obj.uid; if (!acc[key]) acc[key] = {}; acc[key] = obj; return acc; }, {}) : null;
  const _event = _events ? _events[ctx.eventSelected] ?? null : null;

  const SubscribeGuard = useMemo(() => {
    if (!_event) return null;

    // User subscribed
    if (
      _event.maxParticipants &&
      _event.reservation &&
      _event.reservation.includes(ui.user.uid)
    )
      return true;

    if (_event.disableSubscribe) return false;

    // Too much participants
    if (
      _event.maxParticipants &&
      _event.reservation &&
      _event.reservation.length >= _event.maxParticipants
    ) {
      setModalContent({
        title: "L'activité est pleine",
        reason:
          "Le nombre de participants maximum est atteint pour cette activité. Veuillez vous rendre à l'accueil pour obtenir plus d'informations.",
      });
      return false;
    }

    // Subscribtions closed
    if (
      moment(_event.start.seconds * 1000)
        .subtract(_event.closeSubscribe, "days")
        .startOf("D")
        .isBefore(moment())
      && _event.closeSubscribe != 0
    ) {
      setModalContent({
        title: "Les inscriptions sont fermées",
        reason:
          "La date de fermeture des inscriptions est passée.  Veuillez vous rendre à l'accueil pour obtenir plus d'informations.",
      });
      return false;
    }

    // Subscribe not opened
    if (
      moment(_event.start.seconds * 1000)
        .subtract(_event.openSubscribe, "days")
        .startOf("D")
        .isAfter(moment().startOf("D"))
      && _event.openSubscribe != 0
    ) {
      setModalContent({
        title: "Les inscriptions sont fermées",
        reason: `Les inscriptions seront ouvertes à partir du ${moment(
          _event.start.seconds * 1000
        )
          .subtract(_event.openSubscribe, "days")
          .startOf("D")
          .format(
            "dddd Do MMMM YYYY"
          )}.  Veuillez vous rendre à l'accueil pour obtenir plus d'informations.`,
      });
      return false;
    }

    if (
      moment(_event.start.seconds * 1000)
        .isBefore(moment())
    ) {
      // cas spécifique api provence :
      if (ui?.groupement?.specific === "apiprovence") {
        if (moment(_event.end.seconds * 1000).isBefore(moment()))

          setModalContent({
            title: "Les inscriptions sont fermées",
            reason:
              "L'activité a déjà commencé ou est passée. Veuillez vous rendre à l'accueil pour obtenir plus d'informations.",
          });
        return false;

      } else {
        setModalContent({
          title: "Les inscriptions sont fermées",
          reason:
            "L'activité a déjà commencé ou est passée. Veuillez vous rendre à l'accueil pour obtenir plus d'informations.",
        });
        return false;
      }
    }

    return true;
  }, [_event]);

  const onClose = () => {
    dispatch({ type: "setProperty", property: "eventModal", value: false });
    dispatch({ type: "setProperty", property: "eventSelected", value: null });
  };

  const updatePlanning = async (establishmentId, eventId, uid, action) => {
    // action = ["add","remove"]

    const _history = { app: "major-connect-web", action, date: firestore.Timestamp.now(), user: uid };

    if (action == "add") {
      //vérification du max de participants
      let res = await firestore()
        .collection("establishments")
        .doc(establishmentId)
        .collection("blocks")
        .doc("planning")
        .collection("events")
        .doc(eventId)
        .get();

      res = res.data();


      let _modalContent = { canUpdate: true, title: "", reason: "" };

      if (res.maxParticipants)
        if (res.reservation)
          if (res.reservation.length >= res.maxParticipants) {
            _modalContent = {
              canUpdate: false,
              title: "L'activité est pleine",
              reason:
                "Le nombre de participants maximum est atteint pour cette activité. Veuillez vous rendre à l'accueil pour obtenir plus d'informations.",
            };
          }
      if (
        moment(ui?.groupement?.specific === "apiprovence" ? _event.end.seconds * 1000 : _event.start.seconds * 1000)
          .isBefore(moment())
      ) {
        _modalContent = {
          canUpdate: false,
          title: "Les inscriptions sont fermées",
          reason:
            "L'activité a déjà commencé ou est passée. Veuillez vous rendre à l'accueil pour obtenir plus d'informations.",
        };
      }

      if (
        moment(_event.start.seconds * 1000)
          .subtract(_event.closeSubscribe, "days")
          .startOf("D")
          .isBefore(moment())
        && _event.closeSubscribe != 0
      ) {
        _modalContent = {
          canUpdate: false,
          title: "Les inscriptions sont fermées",
          reason:
            "La date de fermeture des inscriptions est passée.  Veuillez vous rendre à l'accueil pour obtenir plus d'informations.",
        };
      }

      if (
        moment(_event.start.seconds * 1000)
          .subtract(_event.openSubscribe, "days")
          .startOf("D")
          .isAfter(moment().startOf("D"))
        && _event.openSubscribe != 0
      ) {
        _modalContent = {
          canUpdate: false,
          title: "Les inscriptions sont fermées",
          reason: `Les inscriptions seront ouvertes à partir du ${moment(
            _event.start.seconds * 1000
          )
            .subtract(_event.openSubscribe, "days")
            .startOf("D")
            .format(
              "dddd Do MMMM YYYY"
            )}.  Veuillez vous rendre à l'accueil pour obtenir plus d'informations.`,
        };
      }

      if (_event.disableSubscribe) {
        _modalContent = {
          canUpdate: false,
          title: "Les inscriptions sont fermées",
          reason:
            "Les inscriptions pour cette animation sont fermées. Veuillez vous rendre à l'accueil pour obtenir plus d'informations.",
        };
      }
      if (_modalContent.canUpdate) {
        await firestore()
          .collection("establishments")
          .doc(establishmentId)
          .collection("blocks")
          .doc("planning")
          .collection("events")
          .doc(eventId)
          .update({
            reservation: firestore.FieldValue.arrayUnion(uid),
            history: firestore.FieldValue.arrayUnion(_history),
          });

        let _user = null;

        if (uid !== ui.user.uid) {
          let _userGet = await firestore().collection("users").doc(uid).get();
          _user = _userGet.data();
        } else {
          _user = ui.user;
        }

        toast.success("Réservation Réussie");
      } else {
        setModalSubscribe(true);
        setModalContent(_modalContent);
      }
    }

    if (action == "remove") {
      let _modalContent = { canUpdate: true, title: "", reason: "" };
      if (
        moment(ui?.groupement?.specific === "apiprovence" ? _event.end.seconds * 1000 : _event.start.seconds * 1000)
          .isBefore(moment())
      ) {
        _modalContent = {
          canUpdate: false,
          title: "Les inscriptions sont fermées",
          reason:
            "L'activité a déjà commencé ou est passée. Veuillez vous rendre à l'accueil pour obtenir plus d'informations.",
        };
      }

      if (
        moment(_event.start.seconds * 1000)
          .subtract(_event.closeSubscribe, "days")
          .startOf("D")
          .isBefore(moment())
        && _event.closeSubscribe != 0
      ) {
        _modalContent = {
          canUpdate: false,
          title: "Les inscriptions sont fermées",
          reason:
            "La date de fermeture des inscriptions est passée.  Veuillez vous rendre à l'accueil pour obtenir plus d'informations.",
        };
      }

      if (
        moment(_event.start.seconds * 1000)
          .subtract(_event.openSubscribe, "days")
          .startOf("D")
          .isAfter(moment().startOf("D"))
        && _event.openSubscribe != 0
      ) {
        _modalContent = {
          canUpdate: false,
          title: "Les inscriptions sont fermées",
          reason: `Les inscriptions seront ouvertes à partir du ${moment(
            _event.start.seconds * 1000
          )
            .subtract(_event.openSubscribe, "days")
            .startOf("D")
            .format(
              "dddd Do MMMM YYYY"
            )}.  Veuillez vous rendre à l'accueil pour obtenir plus d'informations.`,
        };
      }

      if (_event.disableSubscribe) {
        _modalContent = {
          canUpdate: false,
          title: "Les inscriptions sont fermées",
          reason:
            "Les inscriptions pour cette animation sont fermées. Veuillez vous rendre à l'accueil pour obtenir plus d'informations.",
        };
      }

      if (_modalContent.canUpdate) {
        firestore()
          .collection("establishments")
          .doc(establishmentId)
          .collection("blocks")
          .doc("planning")
          .collection("events")
          .doc(eventId)
          .update({
            reservation: firestore.FieldValue.arrayRemove(uid),
            history: firestore.FieldValue.arrayUnion(_history),
          });


        // event.reservation.filter(participant => participant.uid != ui.user.uid);

        toast.success("Annulation Réussie");
      } else {
        setModalSubscribe(true);
        setModalContent(_modalContent);
      }


    }

    if (["add", "remove"].includes(action)) {
      sendNotifToMajor({
        ui,
        data: {
          type: "animation", // ["animation", "menu", "service", ...] 
          action: action.replace("remove", "delete"), // ["add", "update", "delete"]
          data: {
            userId: uid, // l'id de l'utilisateur qui a fait l'action 
            date: _event?.start?.toDate(),
            name: _event?.title, // repas, animation name, prestataire

            // specificités :
            category: _categories[_event.categoryId]?.title, // catégorie de l'animations ou du service
            end: _event?.end?.toDate(), //heure de fin du service / animation
          }
        }
      });
    }




  };

  if (!ctx.eventSelected)
    return (<></>);

  if (!_event) return <></>;

  const _categories = ctx.categories.reduce((acc, obj) => { const key = obj.uid; if (!acc[key]) acc[key] = {}; acc[key] = obj; return acc; }, {});
  const hasBooked = _event.reservation?.includes(ui.user.uid) ?? false;

  return (
    <>
      <div className={styles.absoluteContainer}>
        <div className={styles.modal}>
          <div
            className={styles.header}
            style={{
              backgroundColor:
                _categories[_event.categoryId]?.color ?? "rgb(242, 153, 74)",
            }}
          >
            {_categories[_event?.categoryId]?.smiley ? (
              <img
                src={_categories[_event.categoryId]?.smiley}
                style={{ width: 35, height: 35 }}
              ></img>
            ) : (
              <div></div>
            )}
            <span>{_categories[_event.categoryId]?.title}</span>
            <div className={styles.exit} onClick={() => onClose()}>
              <X size={35} color="red" />
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.bodyHeader}>
              <h3 className={styles.title}>{_event.title}</h3>
              <div className="flex flex-wrap justify-center">
                <div
                  className="w-1/3 p-1 flex items-center justify-center"
                  style={{ gap: 10 }}
                >
                  <Calendar size={22} />
                  <span className={styles.hours}>
                    {moment(_event.start.seconds * 1000).format("HH:mm")} -{" "}
                    {moment(_event.end.seconds * 1000).format("HH:mm")}
                  </span>
                </div>
                {_event.place ? (
                  <div
                    className="w-1/3 p-1 flex items-center justify-center"
                    style={{ gap: 10 }}
                  >
                    <MapPin size={22} />
                    <span className="text-gray-800 text-xl font-italic">
                      {_event.place}
                    </span>
                  </div>
                ) : null}
                {_event.maxParticipants ? (
                  <div
                    className="w-1/3 p-1 flex items-center justify-center"
                    style={{ gap: 10 }}
                  >
                    <Users size={22} />
                    <span className="text-gray-800 text-xl font-italic">
                      {_event.reservation?.length ?? 0} /{" "}
                      {_event.maxParticipants}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className={styles.bodyContent}>
              {_event.image ? (
                <div className={styles.imageContainer}>
                  <img src={_event.image}></img>
                </div>
              ) : null}
              <p className={styles.description}>{_event.description}</p>
            </div>
          </div>
          <div className={styles.footer}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                {isReservation && isSenior ? (
                  <Button
                    color={SubscribeGuard ? "primary" : "primaryDark"}
                    onClick={() =>
                      updatePlanning(
                        ui.user.establishment,
                        _event.uid,
                        ui.user.uid,
                        !hasBooked ? "add" : "remove"
                      )
                    }
                  >
                    {!hasBooked ? "Participer" : "Annuler la participation"}
                  </Button>
                ) : null}
                <Button color="secondary" onClick={() => onClose()}>
                  Retour
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.clickOut} onClick={() => onClose()}></div>
      </div>
      <Modal
        isOpen={modalSubscribe}
        toggle={() => setModalSubscribe(false)}
        size="lg"
        zIndex={15000}
      >
        <ModalHeader>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <span style={{ fontSize: "32px" }}>{modalContent.title}</span>
        </ModalHeader>
        <ModalBody>
          <span>{modalContent.reason}</span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setModalSubscribe(false)}
          >
            Retour
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

EventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default EventModal;
