import React, {useState, useEffect} from "react";
import firebase from "services/firebase";
import useUI from "hooks/ui.hook";
import {Spinner, Button} from "lib/components";
import moment from "moment";
import { useHistory } from "react-router-dom";

import styles from "assets/scss/components/homeWidgets/lastActu.module.scss";
import { InfoCard, InfoCardHeader, InfoCardBody } from "lib/components/InfoCard";
import noImg from "../noImg.jpeg";

const firestore = firebase.firestore;

const LastActus = () => {
  const [ui] = useUI();
  const history = useHistory();
  const [newsEtab, setNewsEtab] = useState([]);
  const [newsGrp, setNewsGrp] = useState([]);

  const [actus, setActus] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      try {
        firestore().collection("establishments").doc(ui.user.establishment)
          .collection("blocks").doc("actus").collection("actus")
          .orderBy("date", "desc").onSnapshot(res => {
            let data = [];
            res.forEach(d => data.push({uid: d.id, ...d.data()}));
            setNewsEtab(data.filter(_a => _a?.isPublished !== false));
          });

          if(ui.groupement){
            let scope = ["national"];
            if(ui.establishment.region)scope.push(ui.establishment.region);
            firestore().collection("groupements").doc(ui.groupement)
            .collection("actus").where("type","in", scope).orderBy("date", "desc").limit(3).onSnapshot(snap => {
              let _data = [];
              snap.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
              setNewsGrp(_data);
            });
                }
      } catch (e) {
        console.error(e.message);
      }
      setLoading(false);
  }, []);

  useEffect(() => {
    let _actus = newsEtab.concat(newsGrp);
    _actus = _actus.sort((a,b) => b.date.toDate() - a.date.toDate()).slice(0,3);
    setActus(_actus);
    setLoading(false);
}, [newsEtab, newsGrp]);
  return (
    <div className={styles.container}>
      {loading ? <Spinner /> : <>
        {!actus && actus.length < 1 ? <>
          <p>Aucune actualité pour votre établissement</p>
        </> :
          <div className={styles.actusContainer}>
          {actus.map((actu, indx) =>(
               <InfoCard height={"large"} key={indx}>
                  <InfoCardHeader className={styles.cardHeaderColor} title={actu.title} tag={actu.type ?? "local"}>
                  </InfoCardHeader>
                  <InfoCardBody image={actu.image ?? noImg}>
                    <div className={styles.content}>
                      <span className={styles.description}>
                        {actu.description}
                      </span>
                      <div className={styles.actuActions}>
                        <span className={styles.actuDate}>Publié le {moment(actu.date.toDate()).format("l")}</span>
                        <Button color="primary" onClick={()=> history.push("/dashboard/news/"+actu.uid)} className={styles.button}>Consulter</Button>
                      </div>
                    </div>
                  </InfoCardBody>
                </InfoCard>              
          ))}
        </div>
        }
      </>}
    </div>
  );
};

export default LastActus;