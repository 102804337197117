import React from "react";
import useUI from "hooks/ui.hook";

import Basic_DaySelector from "./Basic/Components/DaySelector";
import Basic_MenuCard from "./Basic/Components/MenuCard";
import Basic_ModalReservation from "./Basic/Components/ModalReservation";
import Basic_ModalResume from "./Basic/Components/ModalResume";
import Basic_RepasComponent from "./Basic/Components/RepasComponent";
import Basic_CategorySelector from "./Basic/Components/CategorySelector";
import Basic_ModalInformations from "./Basic/Components/ModalInformations";
import Basic_DishCard from "./Basic/Components/DishCard";


import Ovelia_ModalReservation from "./Specific/ovelia/Components/ModalReservation";
import Ovelia_CategorySelector from "./Specific/ovelia/Components/CategorySelector";
import Ovelia_ModalInformations from "./Specific/ovelia/Components/ModalInformations";
import Ovelia_DishCard from "./Specific/ovelia/Components/DishCard";
import Ovelia_RepasComponent from "./Specific/ovelia/Components/RepasComponent";
import Ovelia_ModalResume from "./Specific/ovelia/Components/ModalResume";
import Ovelia_MenuCard from "./Specific/ovelia/Components/MenuCard";

import APIPROVENCE_RepasComponent from "./Specific/apiprovence/Components/RepasComponent";
import APIPROVENCE_ModalReservation from "./Specific/apiprovence/Components/ModalReservation";
import APIPROVENCE_CategorySelector from "./Specific/apiprovence/Components/CategorySelector";


import MarpaBergeDuLion_ModalReservation from "./Specific/marpaBergesDuLion/Components/ModalReservation";


export const CategorySelector = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_CategorySelector {...props} />;
    if (ui?.groupement?.specific === "apiprovence") return <APIPROVENCE_CategorySelector {...props} />;
    return <Basic_CategorySelector {...props} />;
};

export const ModalInformation = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_ModalInformations {...props} />;
    return <Basic_ModalInformations {...props} />;
};

export const DishCard = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_DishCard {...props} />;
    return <Basic_DishCard {...props} />;
};

export const DaySelector = (props) => {
    return <Basic_DaySelector {...props} />;
};

export const MenuCard = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_MenuCard {...props} />;
    return <Basic_MenuCard {...props} />;
};

export const ModalReservation = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_ModalReservation {...props} />;
    if (ui?.establishement?.uid === "Q1K3DO8mh4BO8AAI6SvM") return <MarpaBergeDuLion_ModalReservation {...props} />;
    if (ui?.groupement?.specific === "apiprovence") return <APIPROVENCE_ModalReservation {...props} />;
    return <Basic_ModalReservation {...props} />;
};

export const ModalResume = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_ModalResume {...props} />;
    return <Basic_ModalResume {...props} />;
};

export const RepasComponent = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_RepasComponent {...props} />;
    if (ui?.groupement?.specific === "apiprovence") return <APIPROVENCE_RepasComponent {...props} />;
    return <Basic_RepasComponent {...props} />;
};
