import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  FormGroup,
  Select,
  Tag,
} from "lib/components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faUserTimes, } from "@fortawesome/free-solid-svg-icons";
import { CategorySelector } from "../../../router_components";
import { useMenu } from "../../../router_context";

import deepClone from "helpers/deepClone";
import { PlusCircle, MinusCircle, Trash } from "react-feather";
import moment from "moment";
import deepEqual from "deep-equal";
import styles from "../../../Basic/Assets/scss/modalReservation.module.scss";
import { findSubscriptionForDate } from "../../../../../../helpers/subscription";

// import styles from "assets/scss/pages/Menu/modalReservation.module.scss";

const fixe = ["assiette de fromage", "faisselle"];
const colors = ["#8c66dc", "#dc6681", "#00bfb2", "#DC965A", "#81171B"];
const colorUnite = "#123865";

const ModalReservation = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenu();

  const [reservation, setReservation] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [page, setPage] = useState(0);

  //aliases
  const template = ui.establishment.template;
  const templateSubscription = ui.establishment.templateSubscription;
  const isEmployee = ui.user.role === "employee";
  const currentUser = ui.user;
  const dataDay = ctx?.data[ctx?.selectedDate];
  const isRestaurantOpen = ui.establishment.isRestaurantOpen ?? true;
  const subscriptionObject = findSubscriptionForDate(currentUser?.subscriptions, ctx?.selectedDate);
  const subscription = subscriptionObject?.subscription;

  // console.log(ui);
  useEffect(() => {
    if (!ctx.reservationObject) {
      try {
        setPage(0);
        const _resa = deepClone(ctx.data[ctx.selectedDate].reservation[ui?.user?.uid][ctx.modalInfos.repas]);
        if (_resa) {
          setReservation(_resa);

          if (_resa[0] && _resa[0].status !== "canceled") {
            setEmpty(false);
          } else {
            setEmpty(true);
          }
        } else {
          setReservation([defaultReservationObject()]);
          setEmpty(true);
        }

      } catch (error) {
        setReservation([defaultReservationObject()]);
        setEmpty(true);
        setPage(0);
      }
    }
  }, [ctx.selectedDate, ui?.user?.uid, ctx.modalInfos.repas]);


  const defaultReservationObject = () => {
    const _defaultMenu = {};
    template[ctx.modalInfos.repas].categories.map((_categ) => _defaultMenu[_categ] = null);
    _defaultMenu["supplément"] = null;

    const _resaObj = {
      ..._defaultMenu,
      homeDelivery: (ctx.modalInfos.repas === "Dîner" && isRestaurantOpen === false) ? true : reservation?.[0]?.homeDelivery ?? false
    };

    // console.log( ctx.modalInfos.repas, ctx.modalInfos.repas === "Dîner", isRestaurantOpen , ctx.modalInfos.repas === "Dîner" && isRestaurantOpen === false);
    return _resaObj;
  };

  const _save = () => {
    // ctx.updateMenu(reservation);
    // dispatch({ type: "setProperty", property : "modalResaOpen", value:false});
    // dispatch({ type: "setProperty", property : "modalInfos", value:null});
    dispatch({ type: "setProperty", property: "reservationObject", value: reservation });
    dispatch({ type: "setProperty", property: "modalValidation", value: "save" });
    dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
    dispatch({ type: "setProperty", property: "modalResumeOpen", value: true });
  };

  const _delete = () => {
    // if(confirm("Voulez vous vraiment supprimer votre réservation ?")){
    // ctx.updateMenu("delete");
    // dispatch({ type: "setProperty", property : "modalResaOpen", value:false});
    // dispatch({ type: "setProperty", property : "modalInfos", value:null});
    dispatch({ type: "setProperty", property: "modalValidation", value: "delete" });
    dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
    dispatch({ type: "setProperty", property: "modalResumeOpen", value: true });
    // }
  };



  const updateHomeDelivery = () => {
    let currentValue = reservation[0].homeDelivery;

    let _resa = deepClone(reservation);

    _resa.forEach(element => {
      element.homeDelivery = !currentValue;
    });

    setReservation(_resa);
  };

  const updateSelection = (category, choice) => {
    let currentResa = deepClone(reservation);

    currentResa[page][category] = choice;

    //cas ou on change de dessert, on doit vérifier si un dessert n'était pas sélectionné dans les suppléments.

    if (category === "dessert") {
      const dessertIdList = Object.keys(dataDay[ctx.modalInfos.repas]["dessert"]);

      // s'il y avait un dessert dans les suppléments et qu'un dessert est choisi dans la catégorie dessert. On supprime celui du supplément.
      if (dessertIdList.includes(currentResa[page]["supplément"]) && dessertIdList.includes(choice)) {
        currentResa[page]["supplément"] = null;
      }

      // on doit ajouter le fromage dans le cas où il est pas déjà pris dans la catégorie dessert
      if (choice === fixe[0] && currentResa[page]["supplément"] === fixe[0]) {
        currentResa[page]["supplément"] = null;
      }

      // on doit ajouter la faisselle dans le cas où elle n'est pas déjà prise dans la catégorie dessert.
      if (choice === fixe[1] && currentResa[page]["supplément"] === fixe[1]) {
        currentResa[page]["supplément"] = null;
      }
    }

    setReservation(currentResa);
  };

  const addResa = () => {
    let currentResa = deepClone(reservation);

    currentResa.push(defaultReservationObject());

    setReservation(currentResa);
    setPage(currentResa.length - 1);
  };

  const removeResa = () => {
    let indxToRemove = page > 0 ? page : null;

    if (indxToRemove) {
      let currentResa = deepClone(reservation);

      currentResa.splice(indxToRemove, 1);

      setReservation(currentResa);
      setPage(page - 1);
    }
  };

  const retour = () => {

    if (ctx.modalValidation === "modification") {
      dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
      dispatch({ type: "setProperty", property: "modalResumeOpen", value: true });
    } else {
      dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
    }
  };

  const hasChanged = useMemo(() => {
    // On compare la resa actuelle à la resa initiale :
    const _initial = ctx.data[ctx.selectedDate] && ctx.data[ctx.selectedDate].reservation && ctx.data[ctx.selectedDate].reservation[ui.user.uid] ? ctx.data[ctx.selectedDate].reservation[ui.user.uid][ctx.modalInfos.repas] ?? [] : [];

    if (deepEqual(_initial, reservation)) {
      return false;
    } else {
      return true;
    }
  }, [reservation, ctx]);


  const getFormula = useCallback((_page) => {
    if (ctx.modalInfos.repas === "Déjeuner") {
      let count = 0;
      if (reservation[_page]) {

        if (reservation[_page]["entrée"]) count++;
        if (reservation[_page]["plat"]) count++;
        if (reservation[_page]["dessert"]) count++;
      }

      if (count === 2) return "DUO";
      if (count === 3) return "TRIO";

      return null;
    }
    if (ctx.modalInfos.repas === "Dîner") {
      let count = 0;
      if (reservation[_page]) {

        if (reservation[_page]["entrée"]) count++;
        if (reservation[_page]["plat"]) count++;
        if (reservation[_page]["dessert"]) count++;
      }

      if (count >= 2) return "DUO";
      return null;
    }
    return null;
  }, [reservation, ctx]);


  const isValid = useMemo(() => {
    let _valid = true;

    reservation.forEach((_resa, _page) => {
      if (reservation[0].homeDelivery === true) {
        if (!getFormula(_page)) _valid = false;
      }
    });

    return _valid;
  }, [reservation, ctx]);

  return (
    <Modal isOpen={ctx.modalResaOpen} toggle={() => dispatch({ type: "setProperty", property: "modalResaOpen", value: false })} className={styles.modal}>
      <ModalHeader className={styles.modalHeader}>
        <div className={styles.modalHeaderComponent}>
          {/* <div className={styles.headerInfo}> {currentUser?.surname} {currentUser?.name} {page > 0 ? `(Invité ${page})` : ""}</div> */}
          <div className={styles.headerInfo}></div>

          <div className={styles.modalTitle}>
            Réservation pour le {ctx.modalInfos.repas} du <span className={styles.headerDate}>{moment(ctx?.selectedDate).format("dddd DD MMMM")}</span>
          </div>

          <div className={styles.headerContent}>
            {page === 0 && templateSubscription ?
              subscription ?
                subscription === "1/2p Duo" ?
                  <div>
                    <Tag className={styles.tag} style={{ backgroundColor: colors[0] }}><div>Demi Pension</div></Tag>
                    <Tag className={styles.tag} style={{ backgroundColor: colors[1] }}><div>DUO</div></Tag>
                  </div>
                  :
                  subscription === "1/2p Trio" ?
                    <div>
                      <Tag className={styles.tag} style={{ backgroundColor: colors[0] }}><div>Demi Pension</div></Tag>
                      <Tag className={styles.tag} style={{ backgroundColor: colors[2] }}><div>TRIO</div></Tag>
                    </div>
                    : null
                :
                <Tag className={styles.tag} style={{ backgroundColor: colorUnite }}><div>Unité</div></Tag>

              : null}
          </div>

        </div>
      </ModalHeader>

      <ModalBody className={styles.modalBody}>
        {reservation && reservation[0]?.homeDelivery ?
          <div className={styles.modalContainer}>
            <div className={styles.headerResa}>
              <div className={styles.resaList}>
                <div className={page === 0 ? styles.resaSelected : null} onClick={() => setPage(0)}> {currentUser?.surname} {currentUser?.name}</div>
                {reservation.length > 1 ?
                  reservation.map((_r, indx) => (
                    indx > 0 ?
                      <div key={indx} onClick={() => setPage(indx)} className={page === indx ? styles.resaSelected : null}>
                        <div>
                          {`(Invité ${indx})`}
                        </div>
                      </div>
                      : null
                  ))
                  : null}
              </div>
              <div className={styles.resaButton}>
                {!isEmployee ?
                  <>
                    {page > 0 ? <div color="danger" onClick={removeResa} style={{ color: "red" }}> <FontAwesomeIcon icon={faUserTimes} color="red" /> Supprimer cette invitation</div> : null}
                    <div onClick={addResa}> <FontAwesomeIcon icon={faUserPlus} color="#300438" /> Inviter une personne</div>
                  </>
                  : null}
              </div>
            </div>
            {/* <ModalInformation reservation={reservation} page={page} /> */}
            <div className={styles.content}>

              {reservation ?
                <CategorySelector page={page} dataReservation={reservation[page]} updateSelection={(category, choice) => updateSelection(category, choice)} />
                : null}
            </div>

          </div>
          :
          <div className={styles.containerOvelia}>
            Réservation  de {reservation ? reservation.length : 0} repas pour le {ctx.modalInfos.repas} du {moment(ctx.selectedDate).format("dddd DD MMMM YYYY")}

            <div className={styles.repasSelectorContainer}>
              <div>Nombre de repas :</div>

              <div className={styles.repasSelectorComponent}>
                <MinusCircle onClick={removeResa} size={42} color="#821097" className={styles.icon} />
                <div className={styles.repasCount}>{reservation ? reservation.length : 0}</div>
                <PlusCircle onClick={addResa} size={42} color="#821097" className={styles.icon} />
              </div>
            </div>
          </div>
        }
      </ModalBody>
      <ModalFooter>
        <div className={styles.footer}>
          <div className={styles.footerLeft}>
            <div className={styles.nbresas}>
              Total : {reservation ? reservation.length : 0} repas
            </div>
            {!isEmployee ? <div>
              <Checkbox className={styles.checkbox} label="portage" checked={reservation ? reservation[0]?.homeDelivery : false} disabled={!isRestaurantOpen && ctx.modalInfos.repas === "Dîner"} onChange={updateHomeDelivery} />
            </div> : <div></div>}
          </div>

          <div className={styles.footerInfos}>
            {!isValid ?
              <div className={styles.notValid}>
                Touchez les plats que vous voulez réserver. <br /> Vous devez compléter une formule {reservation.length > 1 ? "(pour chaque invité)" : null}.
              </div> : null}
          </div>

          <div className={styles.footerButtons}>
            {!empty && !(reservation && reservation[0] && reservation[0].createdLocally) ?
              <Button color="danger" onClick={_delete}>Supprimer</Button>
              : null}
            <Button color="secondary" onClick={retour}>Retour</Button>
            {dataDay ? <Button color="primary" disabled={!hasChanged || !isValid} onClick={_save}>Valider</Button> : null}
          </div>

        </div>
      </ModalFooter>
    </Modal>
  );
};

const SuspenseHOC = (props) => {
  const [ctx] = useMenu();

  if (!ctx || !ctx.data || !ctx.modalInfos) return <></>;
  return <ModalReservation {...props} />;
};


export default SuspenseHOC;
